<template>
  <div style="height: 100vh;" class="column no-wrap">
    <!-- logout dialog -->
    <q-dialog v-model="confirm" persistent>
      <q-card class="q-py-md q-px-sm">
        <q-card-section>Apakah Anda akan keluar dari halaman survey?</q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn
            dense
            outline
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="confirm = false"
            label="Tidak"
          />
          <q-btn
            dense
            unelevated
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="closeSurvey"
            label="Ya"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end of logout dialog  -->

    <!-- user info  -->
    <div class="row items-center absolute-top-right q-mr-md">
      <div class="text-grey q-pr-xs">{{ surveyor.nama }}</div>|
      <q-btn dense flat @click="confirm = true" color="red" label="logout" no-caps />
    </div>
    <!-- end of user info  -->

    <div class="col-auto q-pa-md q-pt-xl text-center">
      <div class="text-h6">HASIL SURVEY</div>
      <div>Nama Guru Kepala: {{ surveyor.nama }}</div>
    </div>

    <q-tabs dense v-model="selTab" class="bg-indigo-5 text-white">
      <q-tab v-for="(item, i) in listTab" :key="i" :name="item.name" :label="item.label"></q-tab>
    </q-tabs>

    <q-scroll-area style="background-color: rgb(230, 230, 230);height: calc(100vh - 205px);">
      <!-- tab survey  -->
      <q-list v-if="selTab == 'survey'" separator>
        <q-item
          v-for="(x, i) in result_survey"
          :key="i"
          :style="
            x.id ? (x.nilai_survey > 30 ? 'color: red' : '') : 'color: grey'
          "
        >
          <q-item-section
            avatar
            :style="x.nilai_survey > 30 ? '' : 'color:green'"
          >{{ x.id ? x.nilai_survey : "-" }}</q-item-section>
          <q-item-section class="ellipsis">{{ x.nama }}</q-item-section>
          <q-item-section side top>
            <div class="text-grey-8 q-gutter-xs">
              <q-btn dense v-show="x.masih_karantina" flat icon="masks" color="negative" />

              <q-btn
                v-show="x.hp"
                flat
                dense
                icon="fab fa-whatsapp-square"
                color="green"
                type="a"
                :href="
                'https://api.whatsapp.com/send?phone=' +
                  x.hp +
                  '&text=Assalaamu%27alaikum '
              "
                target="_blank"
              ></q-btn>
              <q-btn
                v-show="x.hp"
                flat
                dense
                icon="phone"
                color="blue"
                type="a"
                :href="
                'tel:' +
                  x.hp 
              "
                target="_blank"
              ></q-btn>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
      <!-- end of tab survey  -->
      <!-- tab kajian  -->
      <q-list v-if="selTab == 'kajian'" separator>
        <q-item v-for="(val, i) in result_kajian" :key="i">
          <q-item-section class="ellipsis">
            <q-item-label>{{val.nama}}</q-item-label>
            <q-item-label caption>
              <strong v-if="val.status_kehadiran == null">-</strong>
              <strong
                v-else
                :style="
                val.status_kehadiran == 'Berjalan'
                  ? 'color: green'
                  : val.status_kehadiran == 'Tidak Berjalan'
                  ? 'color: black'
                  : 'color: red'
              "
              >{{ val.status_kehadiran }}</strong>
            </q-item-label>
          </q-item-section>
          <q-item-section side top>
            <div class="text-grey-8 q-gutter-xs">
              <q-btn
                v-show="val.hp"
                flat
                icon="fab fa-whatsapp-square"
                color="green"
                type="a"
                :href="
                'https://api.whatsapp.com/send?phone=' +
                  val.hp +
                  '&text=Assalaamu%27alaikum '
              "
                target="_blank"
              ></q-btn>
              <q-btn
                v-show="val.hp"
                flat
                dense
                icon="phone"
                color="blue"
                type="a"
                :href="
                'tel:' +
                  val.hp 
              "
                target="_blank"
              ></q-btn>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
      <!-- end of tab kajian  -->
    </q-scroll-area>

    <div class="row items-end">
      <div class="row" style="width: 100%;">
        <q-btn
          class="col-12 q-pa-sm no-border-radius"
          label="Survey Mandiri"
          color="indigo-10"
          @click="$router.push('/survey-guru')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "App",

  data() {
    return {
      selTab: "survey",
      listTab: [
        { name: "survey", label: "survey" },
        { name: "kajian", label: "kajian" }
      ],
      confirm: false,
      surveyor: {},
      step: 2,
      survey: {
        Q1: false,
        Q2: false,
        Q3: false,
        Q4: false,
        Q5: false,
        nilai_survey: 35
      },
      result_survey: [],
      result_masih_karantina: [],
      result_kajian: []
    };
  },
  mounted() {
    this.$http.get("/kode_unik", {}).then(result => {
      if (localStorage.getItem("kode_unik") != result.data.kode_unik) {
        this.$router.push("/");
      }
    });
    this.$http
      .get("/guru_by_noinduk/" + localStorage.getItem("surveyor"), {})
      .then(result => {
        this.surveyor = result.data;
      });

    this.$http
      .get("/hasil_survey_guru/" + localStorage.getItem("surveyor"), {})
      .then(result => {
        this.result_survey = result.data;
        this.$http
          .get("/guru_masih_karantina/" + localStorage.getItem("surveyor"), {})
          .then(result => {
            this.result_masih_karantina = result.data;
            this.result_survey.forEach((x, i) => {
              this.result_masih_karantina.forEach(y => {
                if (x.no_induk == y.no_induk) {
                  this.$set(x, "masih_karantina", true);
                }
              });
            });
          });
      });

    this.$http
      .get("/hasil_kajian_guru/" + localStorage.getItem("surveyor"), {})
      .then(result => {
        this.result_kajian = result.data;
      });
  },
  methods: {
    closeSurvey() {
      localStorage.removeItem("kode_unik");
      localStorage.removeItem("surveyor");
      this.$router.push("/");
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Montserrat";
  src: url("~@/assets/fonts/Montserrat-Medium.otf") format("truetype");
}

* {
  font-family: "Montserrat";
}
</style>
